import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const FilterButton = styled.button`
      color: ${(props) => props.theme.white};
      width: ${(props) => props.width || "100px"};
      border: 0;
      border-radius: 5px;
      padding: 6px 20px;
      margin-top: 2rem;
      background-color: ${(props) => props.theme.primaryColor};

      .spinner-border {
            height: 1rem !important;
            width: 1rem !important;
            border-width: 1px !important;
      }
`;

export const BookingFilter = styled.div`
      .form-label {
            font-size: 14px;
            font-weight: 500;
      }
      input,
      .css-1jqq78o-placeholder {
            font-size: 14px;
      }

      .css-19bb58m {
            padding: 0;
            margin: 0;
      }

      .css-1jqq78o-placeholder {
            font-size: 14px;
      }

      .css-1xc3v61-indicatorContainer {
            padding-top: 6px;
            padding-bottom: 6px;
      }

      .css-1wy0on6 {
            height: 34px;
            min-height: 28px;
            max-height: 34px;
      }

      .css-13cymwt-control,
      .css-b62m3t-container {
            height: 34px;
            min-height: 28px;
            max-height: 34px;
      }

      .css-t3ipsp-control {
            box-shadow: none;
            min-height: 28px;
            height: 34px;
      }

      .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
            font-size: 14px;
      }
`;

export const FlightModal = styled(Modal)`
      .btn-close {
            position: absolute;
            cursor: pointer;
            top: ${(props) => props.top || "10px"};
            right: 20px;
            z-index: 1;
            padding: 10px;
            border-radius: 50rem;
            border: 1px solid #e5e3e3;
            transition: all 0.3s ease-in;
            background-color: #f4f4f4;

            &:hover {
                  background-color: ${(props) => props.theme.primaryColor};
                  border: 1px solid #e5e3e3;
                  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
            }

            &:focus {
                  box-shadow: none;
            }
      }

      .modal-header {
            background-color: ${(props) => props.backgroundColor};
            color: ${(props) => (props.backgroundColor ? "#FFF" : "")};
      }
`;

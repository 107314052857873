import styled from "styled-components";

export const HotelAboutList = styled.div`
      .about__item-list {
            margin-bottom: 20px;

            &:last-child {
                  margin-bottom: 0;
            }
      }
      h4 {
            font-size: 16px;
            font-weight: 510;
            text-transform: capitalize;
      }
      ul {
            margin: 0;
            padding-left: 20px;

            li {
                  position: relative; /* To position the icon or style pseudo-elements */
                  margin-bottom: 10px; /* Spacing between list items */
                  font-size: 15px; /* Adjust font size */
                  margin-bottom: 10px;
            }
      }

      strong {
            font-weight: 510;
      }
`;

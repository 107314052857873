import React from "react";
import {
      FlightConfirmationDownload,
      FlightConfirmationHeader,
} from "../FlightTicket/style";
import { AccordionBody, AccordionHeader } from "react-bootstrap";
import { CommonButton } from "../../../components/common";
import {
      FlightDetailContainer,
      PassengerAccordion,
      PassengerDetail,
      PassengerTable,
      PriceAccordionHeader,
} from "./style";
import {
      BaggageIcon,
      ContactInfoIcon,
      MailIcon,
      MealIcon,
      SeatIcon,
} from "../../../components/icons";
import FlightConfirmDetail from "./FlightDetail";

const TicketInfo = ({
      agentOrder,
      travellerInfos,
      order,
      bookingId,
      tripInfos,
}) => {
      function displayInfo(data) {
            const uniqueSegments = extractUniqueSegments(data);

            // Use map() to iterate over unique segments and display their info
            return uniqueSegments.map((segment) => {
                  const seat = data.ssrSeatInfos
                        ? data.ssrSeatInfos[segment]?.code
                        : "";
                  const meal = data.ssrMealInfos
                        ? data.ssrMealInfos[segment]?.desc
                        : "";
                  const baggage = data.ssrBaggageInfos
                        ? data.ssrBaggageInfos[segment]?.desc
                        : "";

                  return (
                        <div style={{ fontSize: "14px" }}>
                              <strong>{segment} </strong>
                              <br />

                              {seat && (
                                    <span className="text-muted">
                                          <SeatIcon
                                                height="18px"
                                                width="18px"
                                                color="#666"
                                          />{" "}
                                          {seat}{" "}
                                    </span>
                              )}
                              {meal && (
                                    <span className="text-muted">
                                          <MealIcon
                                                height="18px"
                                                width="18px"
                                                color="#666"
                                          />
                                          : {meal}{" "}
                                    </span>
                              )}
                              {baggage && (
                                    <span className="text-muted">
                                          <BaggageIcon
                                                height="18px"
                                                width="18px"
                                                color="#666"
                                          />
                                          : {baggage}
                                    </span>
                              )}
                        </div>
                  );
            });
      }

      // Function to extract unique segments
      function extractUniqueSegments(data) {
            const baggageSegments = Object.keys(data.ssrBaggageInfos || {});
            const mealSegments = Object.keys(data.ssrMealInfos || {});
            const seatSegments = Object.keys(data.ssrSeatInfos || {});

            const allSegments = [
                  ...baggageSegments,
                  ...mealSegments,
                  ...seatSegments,
            ];

            // Use Set to ensure uniqueness
            return [...new Set(allSegments)];
      }

      return (
            <>
                  <FlightConfirmationDownload>
                        <FlightConfirmationHeader>
                              <div className="confirmation__header-title text-success">
                                    Booking Success
                                    <div className="confirmation__header-subtitle">
                                          PNR: {agentOrder?.pnr_number}
                                    </div>
                              </div>
                        </FlightConfirmationHeader>
                        <CommonButton title="Check PNR" />
                  </FlightConfirmationDownload>
                  <FlightConfirmDetail
                        bookingId={bookingId}
                        tripInfos={tripInfos}
                  />
                  <FlightDetailContainer padding="10px 20px">
                        <PassengerAccordion
                              className="border-0 p-0"
                              border={false}
                        >
                              <PriceAccordionHeader
                                    display="block"
                                    className="p-0"
                              >
                                    <AccordionHeader>
                                          Passenger (
                                          <span className="text-muted">{`${travellerInfos[0]?.fN} ${travellerInfos[0]?.lN}`}</span>
                                          )
                                    </AccordionHeader>
                              </PriceAccordionHeader>
                              <AccordionBody>
                                    <div className="card-third pt-0">
                                          <div className="card-body">
                                                <p className="third-title"></p>
                                                <div className="border-top">
                                                      <PassengerTable
                                                            length={
                                                                  travellerInfos?.length
                                                            }
                                                      >
                                                            <thead className="header-third">
                                                                  <tr>
                                                                        <th>
                                                                              S.No.
                                                                        </th>
                                                                        <th>
                                                                              Name
                                                                        </th>
                                                                        <th width="45%">
                                                                              Add
                                                                              Ons
                                                                        </th>
                                                                  </tr>
                                                            </thead>
                                                            <tbody>
                                                                  {travellerInfos?.map(
                                                                        (
                                                                              item,
                                                                              index
                                                                        ) => (
                                                                              <tr
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <td className="text-muted">
                                                                                          {
                                                                                                ++index
                                                                                          }

                                                                                          .
                                                                                    </td>
                                                                                    <td className="text-muted">
                                                                                          <div className="passenger-details">
                                                                                                {`${item?.ti} ${item?.fN} ${item?.lN}`}{" "}
                                                                                          </div>
                                                                                          <PassengerDetail>
                                                                                                {index -
                                                                                                      1 ===
                                                                                                      0 && (
                                                                                                      <>
                                                                                                            <span>
                                                                                                                  <ContactInfoIcon />{" "}
                                                                                                                  {
                                                                                                                        order
                                                                                                                              ?.deliveryInfo
                                                                                                                              ?.contacts
                                                                                                                  }
                                                                                                            </span>
                                                                                                            <span>
                                                                                                                  <MailIcon />{" "}
                                                                                                                  {
                                                                                                                        order
                                                                                                                              ?.deliveryInfo
                                                                                                                              ?.emails
                                                                                                                  }
                                                                                                            </span>
                                                                                                      </>
                                                                                                )}
                                                                                          </PassengerDetail>
                                                                                    </td>

                                                                                    <td className="text-muted">
                                                                                          {item?.ssrBaggageInfos ||
                                                                                          item?.ssrMealInfos ||
                                                                                          item?.ssrSeatInfos ? (
                                                                                                <>
                                                                                                      {displayInfo(
                                                                                                            item
                                                                                                      )}
                                                                                                </>
                                                                                          ) : (
                                                                                                <>
                                                                                                      -
                                                                                                </>
                                                                                          )}
                                                                                    </td>
                                                                              </tr>
                                                                        )
                                                                  )}
                                                            </tbody>
                                                      </PassengerTable>
                                                </div>
                                          </div>
                                    </div>
                              </AccordionBody>
                        </PassengerAccordion>
                  </FlightDetailContainer>
            </>
      );
};

export default TicketInfo;

import { Accordion } from "react-bootstrap";
import styled from "styled-components";

export const PriceSummary = styled.div`
      .price__heading {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
      }

      .support {
            color: ${(props) => props.theme.primary};
      }
      .price__box {
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            background: #fff;
            padding: 20px;
            border-radius: 10px;
      }
      .price__title {
            font-size: 18px;
            font-weight: 400;
            text-align: left;
      }
      .price__people {
            font-size: 13px;
            font-weight: 400;
            text-align: left;
      }
      .price__item {
            padding: 7px 0px;
            border-bottom: 1px solid #e5e3e3;

            &:last-child {
                  border-bottom: 0;
            }
      }
      .total {
            padding: 7px 0px 0px 0px;
            color: ${(props) => props.theme.primary} !important;
      }
      .total__title {
            font-size: 18px;
            font-weight: 400;
            line-height: 35px;
            text-align: left;
      }
`;

export const PriceAccordion = styled(Accordion)`
      border-bottom: ${(props) =>
            props.border ? props.border : "1px solid #e5e3e3"};
      border-top: ${(props) =>
            props.border ? props.border : "1px solid #e5e3e3"};

      .total {
            padding: 0 0px;
      }

      .accordion-button {
            padding: 7px 0px;

            &:focus,
            &:not(.collapsed) {
                  box-shadow: none;
                  background-color: transparent;
            }

            &:not(.collapsed)::after {
                  margin-left: 10px;
            }

            &::after {
                  margin-left: 10px;
                  content: none;
            }
      }

      .accordion-body {
            padding: 0;
      }

      .price__item {
            display: flex;
      }
`;

export const PriceAccordionHeader = styled.div`
      display: ${(props) => props.display || "flex"};
      align-items: center;
      justify-content: space-between;

      span {
            font-size: 14px;
      }
`;

export const PriceIconContainer = styled.div`
      background-color: #f4f4f4;
      border: 1px solid #e5e3e3;
      border-radius: 50rem;
      margin-left: 10px;
      height: 28px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
`;

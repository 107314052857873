import React from "react";
import "./Oneway.css";
import { format } from "date-fns";
import { CardDescription, MailContainer, TicketContainer } from "./style";
import TicketFlightDetail from "./TicketFlightDetail";
import TicketPassengerDetail from "./TicketPassengerDetail";
import { convertAmount, convertFloat } from "../../../data/format";
import { policies } from "../../../data/policy";

const formatDateTime = (dateString) => {
      const date = new Date(dateString);

      // Format date parts
      const day = String(date.getDate()).padStart(2, "0"); // Day
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month (0-based index, so +1)
      const year = date.getFullYear(); // Year

      // Format time parts
      const hours = String(date.getHours()).padStart(2, "0"); // Hours
      const minutes = String(date.getMinutes()).padStart(2, "0"); // Minutes
      const seconds = String(date.getSeconds()).padStart(2, "0"); // Seconds

      // Combine into desired format
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

export const convertDate = (isoString) => {
      const date = new Date(isoString);
      return format(date, "dd-MMM-yyyy");
};

const FlightTicketLayout = ({
      order,
      tripInfos,
      travellerInfos,
      totalPriceInfo,
      markupPrice,
      ref,
      bookingId,
      currentPriceStatus,
      agentOrder,
      profile,
      borderShadow,
      withInfo,
}) => {
      const totalAmount = () => {
            let totalAmount = totalPriceInfo?.fC?.TF + parseFloat(markupPrice);
            if (agentOrder.seriesFareStatus === 1) {
                  totalAmount += agentOrder.commission;
            }
            return totalAmount;
      };

      const otherTaxes = () => {
            let taxes = totalPriceInfo?.fC?.TAF + parseFloat(markupPrice);
            if (agentOrder.seriesFareStatus === 1) {
                  taxes += agentOrder.commission;
            }
            return taxes;
      };

      return (
            <>
                  <TicketContainer padding="0px" ref={ref}>
                        {/* <div className="container my-4 " id="pdf-content">
                              <h2 className="main-title text-center">
                                    Ticket Confirmation
                              </h2>
                        </div> */}

                        <MailContainer
                              className="main-card bg-white"
                              borderShadow={borderShadow}
                              style={{ backgroundColor: "#FFF" }}
                        >
                              <div className="card-body-first mb-2">
                                    <div className="company-info">
                                          {withInfo ? (
                                                <>
                                                      <h5 className="card-title">
                                                            {
                                                                  profile
                                                                        ?.company
                                                                        ?.company_name
                                                            }
                                                      </h5>
                                                      <p className="card-text">
                                                            {
                                                                  profile
                                                                        ?.company
                                                                        ?.office_address
                                                            }
                                                            ,{" "}
                                                            {
                                                                  profile
                                                                        ?.company
                                                                        ?.state
                                                            }{" "}
                                                            - {profile?.pincode}
                                                            <br />
                                                            <strong>
                                                                  Email:{" "}
                                                                  {
                                                                        profile
                                                                              ?.personal
                                                                              ?.email
                                                                  }
                                                            </strong>
                                                            <br />
                                                            <strong>
                                                                  Mobile No:{" "}
                                                                  {
                                                                        profile
                                                                              ?.personal
                                                                              ?.phone_number
                                                                  }
                                                            </strong>
                                                      </p>
                                                </>
                                          ) : (
                                                ""
                                          )}
                                    </div>

                                    <div className="pnr-section">
                                          <div className="d-flex gap-3">
                                                <div>
                                                      <p className="airline-name">
                                                            {
                                                                  tripInfos[0]
                                                                        ?.sI[0]
                                                                        ?.fD?.aI
                                                                        ?.name
                                                            }
                                                      </p>
                                                      <img
                                                            src={`/images/AirlinesLogo/${tripInfos[0]?.sI[0]?.fD?.aI?.code}.png`}
                                                            alt="IndiGo Logo"
                                                            className="airline-logo"
                                                      />
                                                      <div className="pnr-info">
                                                            <h6 className="pnr">
                                                                  {
                                                                        travellerInfos[0]
                                                                              ?.pnrDetails[
                                                                              `${tripInfos[0]?.sI[0]?.da?.code}-${tripInfos[0]?.sI[0]?.aa?.code}`
                                                                        ]
                                                                  }
                                                            </h6>
                                                      </div>
                                                </div>
                                                {tripInfos[1] && (
                                                      <div>
                                                            <p className="airline-name">
                                                                  {
                                                                        tripInfos[1]
                                                                              ?.sI[0]
                                                                              ?.fD
                                                                              ?.aI
                                                                              ?.name
                                                                  }
                                                            </p>
                                                            <img
                                                                  src={`/images/AirlinesLogo/${tripInfos[1]?.sI[0]?.fD?.aI?.code}.png`}
                                                                  alt="IndiGo Logo"
                                                                  className="airline-logo"
                                                            />
                                                            <div className="pnr-info m-0">
                                                                  <h6 className="pnr">
                                                                        {
                                                                              travellerInfos[0]
                                                                                    ?.pnrDetails[
                                                                                    `${tripInfos[0]?.sI[0]?.aa?.code}-${tripInfos[0]?.sI[0]?.da?.code}`
                                                                              ]
                                                                        }
                                                                  </h6>
                                                            </div>
                                                      </div>
                                                )}
                                          </div>
                                          <p className="pnr-label">
                                                Airline PNR
                                          </p>
                                    </div>

                                    <div className="reference-info">
                                          <p>
                                                Reference Number:{" "}
                                                <strong>{bookingId}</strong>
                                          </p>
                                          <p>
                                                Issued On:{" "}
                                                <strong>
                                                      {order &&
                                                            formatDateTime(
                                                                  order?.createdOn
                                                            )}
                                                </strong>
                                          </p>
                                    </div>
                              </div>

                              <TicketFlightDetail tripInfos={tripInfos} />

                              <TicketPassengerDetail
                                    order={order}
                                    travellerInfos={travellerInfos}
                                    tripInfos={tripInfos}
                              />

                              {currentPriceStatus ? (
                                    <div className=" mb-4">
                                          <div className="card-body">
                                                <h5 className="card-title">
                                                      Payment Details
                                                </h5>
                                                <div className="payment-details">
                                                      <div className="payment-row">
                                                            <span>
                                                                  Base Fare
                                                            </span>
                                                            <span className="price">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              totalPriceInfo
                                                                                    ?.fC
                                                                                    ?.BF
                                                                        )
                                                                  )}
                                                            </span>
                                                      </div>
                                                      <div className="payment-row">
                                                            <span>
                                                                  Taxes and Fees
                                                            </span>
                                                            <span className="price">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              otherTaxes()
                                                                        )
                                                                  )}
                                                            </span>
                                                      </div>
                                                      {totalPriceInfo?.fC
                                                            ?.SSRP && (
                                                            <div className="payment-row">
                                                                  <span>
                                                                        Add Ons
                                                                  </span>
                                                                  <span className="price">
                                                                        ₹{" "}
                                                                        {convertAmount(
                                                                              convertFloat(
                                                                                    totalPriceInfo
                                                                                          ?.fC
                                                                                          ?.SSRP
                                                                              )
                                                                        )}
                                                                  </span>
                                                            </div>
                                                      )}
                                                      <div className="payment-row gross-fare">
                                                            <span>
                                                                  Gross Fare
                                                            </span>
                                                            <span className="price">
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              totalAmount()
                                                                        )
                                                                  )}
                                                            </span>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              ) : (
                                    ""
                              )}
                              <CardDescription>
                                    <div className="card-body">
                                          <h5 className="card-title">
                                                Additional Information
                                          </h5>
                                          <ul className="card-desc-content">
                                                {policies?.map(
                                                      (item, index) => (
                                                            <li key={index}>
                                                                  {item.title}
                                                            </li>
                                                      )
                                                )}
                                                <li>
                                                      <strong>
                                                            Disclaimer:
                                                      </strong>{" "}
                                                      No meal will be served on
                                                      flights that have less
                                                      than 2 hours of flight
                                                      duration.
                                                </li>
                                          </ul>
                                    </div>
                              </CardDescription>
                        </MailContainer>
                  </TicketContainer>
            </>
      );
};

export default FlightTicketLayout;

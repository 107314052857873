import { Route } from "react-router-dom";
import {
      PackageCreate,
      PackageEdit,
      PackageList,
      PackageShow,
} from "../backend/packages";
import {
      PackageCategoryCreate,
      PackageCategoryEdit,
      PackageCategoryList,
      PackageCategoryShow,
} from "../backend/package_categories";
import {
      PackageEnquiryList,
      PackageEnquiryShow,
} from "../backend/package_enquiries";
import {
      DestinationCreate,
      DestinationEdit,
      DestinationList,
      DestinationShow,
} from "../backend/destinations";
import {
      DestinationCategoryCreate,
      DestinationCategoryEdit,
      DestinationCategoryList,
      DestinationCategoryShow,
} from "../backend/destination_categories";
import {
      HotelCategoryCreate,
      HotelCategoryEdit,
      HotelCategoryList,
      HotelCategoryShow,
} from "../backend/hotel_categories";
import {
      VehicleCreate,
      VehicleEdit,
      VehicleList,
      VehicleShow,
} from "../backend/vehicles";
import {
      FlightDestinationCreate,
      FlightDestinationEdit,
      FlightDestinationList,
      FlightDestinationShow,
} from "../backend/flight_destinations";
import { HotelDestinationList } from "../backend/hotel_destinations";
import {
      FlightBookingList,
      FlightBookingShow,
} from "../backend/flight_bookings";
import { HotelBookingList, HotelBookingShow } from "../backend/hotel_bookings";

export const tourManages = [
      // Flight
      <Route
            path="/dashboard/flight_bookings"
            element={<FlightBookingList />}
      />,
      <Route
            path="/dashboard/flight_bookings/:bookingId/:optionId"
            element={<FlightBookingShow />}
      />,

      // Flight Destination
      <Route
            path="/dashboard/flight_destinations"
            element={<FlightDestinationList />}
      />,
      <Route
            path="/dashboard/flight_destinations/create"
            element={<FlightDestinationCreate />}
      />,
      <Route
            path="/dashboard/flight_destinations/:destinationId/edit"
            element={<FlightDestinationEdit />}
      />,
      <Route
            path="/dashboard/flight_destinations/:destinationId/show"
            element={<FlightDestinationShow />}
      />,

      // Destination
      <Route path="/dashboard/destinations" element={<DestinationList />} />,
      <Route
            path="/dashboard/destinations/create"
            element={<DestinationCreate />}
      />,
      <Route
            path="/dashboard/destinations/:destinationId/edit"
            element={<DestinationEdit />}
      />,
      <Route
            path="/dashboard/destinations/:destinationId/show"
            element={<DestinationShow />}
      />,

      // Destination Category
      <Route
            path="/dashboard/destination_categories"
            element={<DestinationCategoryList />}
      />,
      <Route
            path="/dashboard/destination_categories/create"
            element={<DestinationCategoryCreate />}
      />,
      <Route
            path="/dashboard/destination_categories/:categoryId/edit"
            element={<DestinationCategoryEdit />}
      />,
      <Route
            path="/dashboard/destination_categories/:categoryId/show"
            element={<DestinationCategoryShow />}
      />,

      // Hotel Category
      <Route
            path="/dashboard/hotel_categories"
            element={<HotelCategoryList />}
      />,
      <Route
            path="/dashboard/hotel_categories/create"
            element={<HotelCategoryCreate />}
      />,
      <Route
            path="/dashboard/hotel_categories/:typeId/edit"
            element={<HotelCategoryEdit />}
      />,
      <Route
            path="/dashboard/hotel_categories/:typeId/show"
            element={<HotelCategoryShow />}
      />,

      // Hotel Destination
      <Route
            path="/dashboard/hotel_destinations"
            element={<HotelDestinationList />}
      />,

      // Hotel Booking
      <Route path="/dashboard/hotel_bookings" element={<HotelBookingList />} />,
      <Route
            path="/dashboard/hotel_bookings/:bookingId/:optionId"
            element={<HotelBookingShow />}
      />,

      // Vehicles
      <Route path="/dashboard/vehicles" element={<VehicleList />} />,
      <Route path="/dashboard/vehicles/create" element={<VehicleCreate />} />,
      <Route
            path="/dashboard/vehicles/:vehicleId/edit"
            element={<VehicleEdit />}
      />,
      <Route
            path="/dashboard/vehicles/:vehicleId/show"
            element={<VehicleShow />}
      />,

      // Package
      <Route path="/dashboard/packages" element={<PackageList />} />,
      <Route path="/dashboard/packages/create" element={<PackageCreate />} />,
      <Route
            path="/dashboard/packages/:packageId/edit"
            element={<PackageEdit />}
      />,
      <Route
            path="/dashboard/packages/:packageId/show"
            element={<PackageShow />}
      />,
      // Package Category
      <Route
            path="/dashboard/package_categories"
            element={<PackageCategoryList />}
      />,
      <Route
            path="/dashboard/package_categories/create"
            element={<PackageCategoryCreate />}
      />,
      <Route
            path="/dashboard/package_categories/:categoryId/edit"
            element={<PackageCategoryEdit />}
      />,
      <Route
            path="/dashboard/package_categories/:categoryId/show"
            element={<PackageCategoryShow />}
      />,

      // Package Enquiry
      <Route
            path="/dashboard/package_enquiries"
            element={<PackageEnquiryList />}
      />,
      <Route
            path="/dashboard/package_enquiries/:enquiryId/show"
            element={<PackageEnquiryShow />}
      />,
];

import React from "react";
import { FaPerson } from "react-icons/fa6";
import HotelRating from "../HotelInfo/HotelRating";
import { FaCircleCheck } from "react-icons/fa6";
import { HotelAboutList } from "./style";
import { format } from "date-fns";
import {
      calculateTotalAdults,
      calculateTotalChild,
      calculateTotalNights,
      convertAmount,
      convertFloat,
      convertFormatDay,
      formatDateTime,
} from "../../../data/format";
export const convertDate = (isoString) => {
      const date = new Date(isoString);
      return format(date, "dd-MMM-yyyy");
};

const HotelTicketView = ({
      withInfo,
      profile,
      bookingId,
      order,
      priceInfos,
      tripInfos,
      searchQuery,
      agentOrder,
      withPriceStatus,
      markupPrice,
      totalAmount,
}) => {
      const totalNights =
            searchQuery?.checkinDate &&
            searchQuery?.checkoutDate &&
            calculateTotalNights(
                  searchQuery?.checkinDate,
                  searchQuery?.checkoutDate
            );

      const totalAdults = searchQuery
            ? calculateTotalAdults(searchQuery?.roomInfo)
            : 0;

      const totalChild = searchQuery
            ? calculateTotalChild(searchQuery?.roomInfo)
            : 0;

      const totalGuests = totalAdults + totalChild;

      const firstGuest =
            priceInfos?.ris &&
            priceInfos?.ris[0]?.ti &&
            priceInfos?.ris[0]?.ti[0];

      const handleTAF = () => {
            let totalTAF = 0;
            priceInfos?.ris?.map((item) => (totalTAF += item?.tfcs?.TAF));
            return totalTAF;
      };

      const handleBaseFare = () => {
            let totalBaseFare = 0;
            priceInfos?.ris?.map(
                  (item) =>
                        (totalBaseFare +=
                              item?.tfcs?.BF + agentOrder?.commission)
            );
            return totalBaseFare;
      };

      return (
            <>
                  <div className="booking-confirmation" id="pdf-content">
                        <div className="inner">
                              {/* Header Section */}
                              {withInfo ? (
                                    <div className="row py-4">
                                          <div className="col-md-6"></div>
                                          <div className="col-md-6 top-content-name">
                                                <h5 className="card-title">
                                                      {
                                                            profile?.company
                                                                  ?.company_name
                                                      }
                                                </h5>
                                                <p className="card-text">
                                                      {
                                                            profile?.company
                                                                  ?.office_address
                                                      }
                                                      ,{" "}
                                                      {profile?.company?.state}{" "}
                                                      - {profile?.pincode}
                                                      <br />
                                                      <strong>
                                                            Email:{" "}
                                                            {
                                                                  profile
                                                                        ?.personal
                                                                        ?.email
                                                            }
                                                      </strong>
                                                      <br />
                                                      <strong>
                                                            Mobile No:{" "}
                                                            {
                                                                  profile
                                                                        ?.personal
                                                                        ?.phone_number
                                                            }
                                                      </strong>
                                                </p>
                                          </div>
                                    </div>
                              ) : (
                                    ""
                              )}

                              {/* Booking Details */}
                              <div
                                    className={`booking-details py-3 ${
                                          !withInfo ? "border-top-0" : ""
                                    }`}
                              >
                                    <div className="booking-ticket-width booking-ticket">
                                          <p>
                                                Booking ID
                                                <strong>: {bookingId} </strong>
                                          </p>
                                    </div>

                                    <div className="booking-ticket-width booking-ticket">
                                          <p>
                                                Booking Date
                                                <strong>
                                                      :{" "}
                                                      {order?.createdOn
                                                            ? convertDate(
                                                                    order?.createdOn
                                                              )
                                                            : "-"}
                                                </strong>
                                          </p>
                                    </div>
                                    <div className="booking-ticket-width booking-ticket">
                                          <p>
                                                Booking PNR
                                                <strong>: -</strong>
                                          </p>
                                    </div>
                                    <div className="booking-ticket-width text-end ">
                                          <span className="badge booking__confirmed rounded-pill">
                                                <FaCircleCheck /> Booking
                                                Confirmed
                                          </span>
                                    </div>
                              </div>

                              {/* Hotel Info */}
                              <div className="hotel-info py-4">
                                    <div className="hotel-info-detail-8 hotel-info-details">
                                          <h5 className="hotel-info-details">
                                                Hotel in {tripInfos?.ad?.ctn} |{" "}
                                                <strong>
                                                      {" "}
                                                      {totalNights} Night
                                                      {totalNights > 1
                                                            ? "s"
                                                            : ""}
                                                </strong>
                                          </h5>
                                          <h6 className="hotel-info-details">
                                                <strong>
                                                      {tripInfos?.name}
                                                </strong>{" "}
                                                <HotelRating
                                                      num={tripInfos?.rt}
                                                />
                                          </h6>
                                          <p className="hotel-info-details">
                                                {tripInfos?.ad?.adr},{" "}
                                                {tripInfos?.ad?.city?.name}
                                          </p>
                                          <p className="hotel-info-details">
                                                Contact Number -{" "}
                                                {tripInfos?.cnt?.ph}
                                          </p>
                                          {/* <p className="hotel-info-details">
                                                Email ID -{" "}
                                                <a href="mailto:Kuhelika.ved@accor.com">
                                                      Kuhelika.ved@accor.com,h8562-re@accor.com,h8562-du@accor.com
                                                </a>
                                          </p> */}
                                    </div>
                                    <div className="hotel-info-detail-4 text-end">
                                          <a
                                                href={`http://maps.google.com/maps?q=${tripInfos?.gl?.lt},${tripInfos?.gl?.ln}`}
                                                className="btn btn-primary"
                                          >
                                                Get Directions
                                          </a>
                                    </div>
                              </div>

                              <div className="py-4 check-details">
                                    <div className="booking-ticket-width">
                                          <img
                                                src="/images/no-image.webp"
                                                className="img-fluid"
                                                alt="Hotel Image"
                                          />
                                    </div>

                                    <div className="hotel-info-detail-8">
                                          <div className="check-details">
                                                <div className="hotel-info-detail-6">
                                                      <p className="check">
                                                            <strong>
                                                                  Check In
                                                            </strong>
                                                      </p>
                                                      {/* <p className="time">03:00 PM</p> */}
                                                      <p className="date">
                                                            {priceInfos?.ris
                                                                  ? convertFormatDay(
                                                                          priceInfos
                                                                                ?.ris[0]
                                                                                ?.checkInDate
                                                                    )
                                                                  : "-"}
                                                      </p>
                                                </div>
                                                <div className="hotel-info-detail-6">
                                                      <p className="check">
                                                            <strong>
                                                                  Check Out
                                                            </strong>
                                                      </p>
                                                      {/* <p className="time">12:00 PM</p> */}
                                                      <p className="date">
                                                            {priceInfos?.ris
                                                                  ? convertFormatDay(
                                                                          priceInfos
                                                                                ?.ris[0]
                                                                                ?.checkOutDate
                                                                    )
                                                                  : "-"}
                                                      </p>
                                                </div>
                                                <div className="hotel-info-detail-12">
                                                      <div className="check-details reservation-details">
                                                            <div className="reservation-details-content">
                                                                  <p>
                                                                        <strong>
                                                                              Reservation
                                                                              for
                                                                        </strong>{" "}
                                                                        -{" "}
                                                                        {
                                                                              totalNights
                                                                        }{" "}
                                                                        Night
                                                                        {totalNights >
                                                                        1
                                                                              ? "s"
                                                                              : ""}{" "}
                                                                        |{" "}
                                                                        {
                                                                              totalAdults
                                                                        }{" "}
                                                                        Adult
                                                                        {totalAdults >
                                                                        1
                                                                              ? "s"
                                                                              : ""}
                                                                        ,{" "}
                                                                        {`${totalChild} ${
                                                                              totalChild >
                                                                              1
                                                                                    ? "Child "
                                                                                    : "Children "
                                                                        }`}
                                                                        |{" "}
                                                                        {
                                                                              priceInfos
                                                                                    ?.ris
                                                                                    ?.length
                                                                        }{" "}
                                                                        Room
                                                                        {priceInfos
                                                                              ?.ris
                                                                              ?.length >
                                                                        1
                                                                              ? "s"
                                                                              : ""}
                                                                        <br />
                                                                        {firstGuest &&
                                                                              `${firstGuest?.ti} ${firstGuest?.fN} ${firstGuest?.lN} `}
                                                                        (Primary
                                                                        Guest)
                                                                        {`${
                                                                              totalGuests >
                                                                              1
                                                                                    ? " + " +
                                                                                      (totalGuests -
                                                                                            1)
                                                                                    : ""
                                                                        } ${
                                                                              totalGuests >
                                                                              1
                                                                                    ? "Others"
                                                                                    : ""
                                                                        }`}
                                                                  </p>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>

                              {/* room details */}
                              {priceInfos?.ris?.map((item, index) => (
                                    <div className="room-details-container">
                                          <div className="room-card">
                                                <div className="room-info">
                                                      <strong>
                                                            Room {++index} -
                                                      </strong>{" "}
                                                      {item?.rc}
                                                      <div className="room-guests">
                                                            <FaPerson />{" "}
                                                            {item?.adt}{" "}
                                                            Adult(s),{" "}
                                                            {item?.chd} Children
                                                      </div>
                                                </div>
                                                {item?.rexb &&
                                                      item?.rexb["BENEFIT"] &&
                                                      item?.rexb[
                                                            "BENEFIT"
                                                      ]?.[0] &&
                                                      item?.rexb["BENEFIT"]?.[0]
                                                            ?.values && (
                                                            <div className="meal-plan">
                                                                  Facility -{" "}
                                                                  <strong>
                                                                        {item?.rexb[
                                                                              "BENEFIT"
                                                                        ]?.[0]?.values?.map(
                                                                              (
                                                                                    benefit
                                                                              ) =>
                                                                                    `${benefit}, `
                                                                        )}
                                                                  </strong>
                                                            </div>
                                                      )}
                                          </div>
                                    </div>
                              ))}
                              <div className="room-details-container">
                                    <div className="room-card">
                                          <div className="room-info">
                                                <strong>
                                                      Guest Details (
                                                      {totalGuests})
                                                </strong>
                                                <div className="room-guests">
                                                      <strong>
                                                            {firstGuest &&
                                                                  `${firstGuest?.ti} ${firstGuest?.fN} ${firstGuest?.lN} `}
                                                      </strong>{" "}
                                                      (Primary Guest){" "}
                                                      {`${
                                                            totalGuests > 1
                                                                  ? " + " +
                                                                    (totalGuests -
                                                                          1)
                                                                  : ""
                                                      }`}
                                                </div>
                                          </div>
                                          {priceInfos?.ris?.map((guest) =>
                                                guest?.ti?.map(
                                                      (item, index) =>
                                                            firstGuest?.fN !==
                                                                  item?.fN && (
                                                                  <div
                                                                        className="meal-plan"
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <strong>
                                                                              {`${item?.ti} ${item?.fN} ${item?.lN} `}
                                                                        </strong>
                                                                  </div>
                                                            )
                                                )
                                          )}
                                    </div>
                              </div>

                              {/* policy */}
                              <div className="cancellation-policy-container">
                                    <div className="policy-header">
                                          <h4>
                                                Cancellation and Amendment
                                                Policy
                                          </h4>
                                          <hr />
                                    </div>
                                    <p>
                                          Free Cancellation (100% refund) if you
                                          cancel this booking before
                                          <strong>
                                                {" "}
                                                {formatDateTime(
                                                      priceInfos?.ddt
                                                )}
                                          </strong>{" "}
                                          (destination time). Cancellations made
                                          after{" "}
                                          <strong>
                                                {formatDateTime(
                                                      priceInfos?.ddt
                                                )}
                                          </strong>{" "}
                                          (destination time) will be subject to
                                          a hotel fee equal to the 100% of the
                                          booking amount.
                                    </p>
                              </div>

                              {/* payment */}
                              {withPriceStatus && (
                                    <div className="payment-breakup-container">
                                          <div className="payment-breakup-header">
                                                <span>
                                                      Payment Breakup (in INR)
                                                </span>
                                          </div>
                                          <div className="">
                                                <div className="payment-row">
                                                      <span>Base Charges</span>
                                                      <span className="price">
                                                            ₹{" "}
                                                            {convertAmount(
                                                                  convertFloat(
                                                                        handleBaseFare() +
                                                                              markupPrice
                                                                  )
                                                            )}
                                                      </span>
                                                </div>
                                                <div className="payment-row">
                                                      <span>
                                                            Fee and Surcharges
                                                      </span>
                                                      <span className="price">
                                                            ₹ {handleTAF()}
                                                      </span>
                                                </div>
                                                <div className="payment-row total-amount">
                                                      <span>
                                                            <strong>
                                                                  Total Amount
                                                                  Paid
                                                            </strong>
                                                      </span>
                                                      <span className="price total">
                                                            <strong>
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              totalAmount +
                                                                                    markupPrice
                                                                        )
                                                                  )}
                                                            </strong>
                                                      </span>
                                                </div>
                                          </div>
                                    </div>
                              )}
                              {/* information */}
                              <div className="important-info">
                                    {tripInfos?.inst && (
                                          <>
                                                <h4>Booking Notes</h4>
                                                <HotelAboutList
                                                      listStyle={true}
                                                >
                                                      <div className="instructions-container">
                                                            {tripInfos?.inst?.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => {
                                                                        const message =
                                                                              JSON.parse(
                                                                                    item.msg
                                                                              ); // Parse the JSON message
                                                                        return (
                                                                              <div
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                                    className="about__item-list"
                                                                              >
                                                                                    <h6
                                                                                          style={{
                                                                                                fontSize: "14px",
                                                                                                fontWeight: 600,
                                                                                          }}
                                                                                    >
                                                                                          {item.type.replace(
                                                                                                "_",
                                                                                                " "
                                                                                          )}
                                                                                    </h6>
                                                                                    <ul>
                                                                                          {Object.entries(
                                                                                                message
                                                                                          ).map(
                                                                                                (
                                                                                                      [
                                                                                                            key,
                                                                                                            value,
                                                                                                      ],
                                                                                                      idx
                                                                                                ) => (
                                                                                                      <li
                                                                                                            key={
                                                                                                                  idx
                                                                                                            }
                                                                                                      >
                                                                                                            <strong>
                                                                                                                  {key.replace(
                                                                                                                        /_/g,
                                                                                                                        " "
                                                                                                                  )}

                                                                                                                  :
                                                                                                            </strong>{" "}
                                                                                                            {
                                                                                                                  value
                                                                                                            }
                                                                                                      </li>
                                                                                                )
                                                                                          )}
                                                                                    </ul>
                                                                              </div>
                                                                        );
                                                                  }
                                                            )}
                                                      </div>
                                                </HotelAboutList>
                                          </>
                                    )}
                                    <h4>General Terms & Conditions</h4>
                                    <div className="info-box">
                                          <ol>
                                                <li>
                                                      Each country/state may
                                                      have its own set of
                                                      COVID-19 guidelines and
                                                      restrictions. Please check
                                                      with the hotel or visit
                                                      the country’s/state's
                                                      website for the same.
                                                </li>
                                                <li>
                                                      Your booking is confirmed.
                                                      However, your name will be
                                                      listed in the hotel’s
                                                      reservation system closer
                                                      to your arrival date.
                                                </li>
                                                <li>
                                                      Guest Photo Id must be
                                                      presented at the time of
                                                      check-in.
                                                </li>
                                                <li>
                                                      Credit card or cash
                                                      deposit may be required
                                                      for extra services at the
                                                      time of check-in.
                                                </li>
                                                <li>
                                                      All extra charges will be
                                                      borne by the guest
                                                      directly prior to
                                                      departure.
                                                </li>
                                                <li>
                                                      Extra-person and/or
                                                      Extra-bed charges may
                                                      apply and vary depending
                                                      on property policy.
                                                </li>
                                                <li>
                                                      In case of the guest
                                                      arrival delayed or
                                                      postponed due to any
                                                      unforeseen occurrences,
                                                      additional charges will be
                                                      borne by the guest.
                                                </li>
                                                <li>
                                                      In case of incorrect
                                                      residency and nationality
                                                      chosen by the user at the
                                                      time of booking,
                                                      additional charges may be
                                                      applicable which will be
                                                      borne by the guest and
                                                      paid to the hotel at the
                                                      time of checkin/check-out.
                                                </li>
                                                <li>
                                                      Any special requests are
                                                      all subject to
                                                      availability at the time
                                                      of check-in and are not
                                                      guaranteed at the time of
                                                      booking (bed type, smoking
                                                      room, early check-in, late
                                                      check-out etc.).
                                                </li>
                                                <li>
                                                      Full cancellation charges
                                                      are applicable on early
                                                      check-out unless otherwise
                                                      specified.
                                                </li>
                                                <li>
                                                      Hotels do not permit
                                                      unmarried or unrelated
                                                      couples and it is at the
                                                      hotel management’s
                                                      discretion to allow or
                                                      cancel the booking. In
                                                      such case no refund is
                                                      applicable if the hotel
                                                      disallows check-in.
                                                </li>
                                                <li>
                                                      {" "}
                                                      City tax and resort fee
                                                      (if any) are to be paid
                                                      directly to the hotel.
                                                </li>
                                                <li>
                                                      If your booking offers
                                                      complimentary car transfer
                                                      you need to inform the
                                                      hotel of your travel
                                                      details 24 hours prior to
                                                      check-in.
                                                </li>
                                                <li>
                                                      Additional GST Payment (if
                                                      any) to be paid to the
                                                      hotel directly by the
                                                      guest.
                                                </li>
                                                <li>
                                                      As per RBI guidelines: in
                                                      case of foreign nationals,
                                                      it is mandatory to submit
                                                      a passport copy of the
                                                      guest. Please send a
                                                      scanned copy of the
                                                      guest's passport to us.
                                                      Failure to comply may
                                                      result in the cancellation
                                                      of the booking without
                                                      notice.
                                                </li>
                                          </ol>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};

export default HotelTicketView;

import React, { useEffect, useState } from "react";
import BookingLayout from "../flight_bookings/BookingLayout";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { handleHotelCommissionTotal } from "../../data/format";
import axios from "axios";
import toast from "react-hot-toast";
import HotelTicket from "./HotelTicket";
import HotelInfo from "./HotelInfo";
import FlightBookingRate from "../flight_bookings/FlightBookingRate";

const HotelBookingShow = () => {
      const [loading, setLoading] = useState(false);
      const { optionId } = useParams();
      const { bookingId } = useParams();
      const [agentOrder, setAgentOrder] = useState([]);
      const [profile, setProfile] = useState([]);

      const [order, setOrder] = useState([]);
      const [tripInfos, setTripInfos] = useState([]);
      const [priceInfos, setPriceInfos] = useState([]);
      const [totalAmount, setTotalAmount] = useState([]);
      const [searchQuery, setSearchQuery] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const handleTotalPrice = ({ prices, commissionsAmount }) => {
            const totalAmt = handleHotelCommissionTotal({
                  prices: prices,
                  commissionsAmount: commissionsAmount,
            });
            return totalAmt;
      };

      const loadData = async () => {
            setLoading(true);

            const response = await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/hotel_bookings/${bookingId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .catch((error) => {
                        toast.error(error.message);
                  });

            if (response?.data?.result === "success") {
                  setAgentOrder(response.data.agent);
                  setProfile(response.data.agentDetail);
                  await axios
                        .post(
                              `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/hotel/booking-details`,
                              {
                                    bookingId:
                                          response?.data?.agent?.tj_booking_id,
                                    requirePaxPricing: true,
                              },
                              {
                                    headers: {
                                          apikey: process.env
                                                .REACT_APP_Flight_Api_Key,
                                    },
                              }
                        )
                        .then((response) => {
                              if (response.data.status?.success === true) {
                                    setOrder(response.data.order);
                                    setTripInfos(
                                          response.data.itemInfos?.HOTEL?.hInfo
                                    );
                                    setPriceInfos(
                                          response.data.itemInfos?.HOTEL?.hInfo
                                                ?.ops[0]
                                    );
                                    setSearchQuery(
                                          response.data.itemInfos?.HOTEL?.query
                                    );
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            }
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      useEffect(() => {
            setTotalAmount(
                  handleTotalPrice({
                        prices: priceInfos?.ris,
                        commissionsAmount: agentOrder?.commission,
                  })
            );
      }, [priceInfos, agentOrder]);

      return (
            <>
                  <BookingLayout
                        title="Hotel"
                        optionId={optionId}
                        bookingId={bookingId}
                        slug="hotel_bookings"
                  >
                        {!loading ? (
                              <>
                                    {optionId === "ticket-info" && (
                                          <HotelInfo
                                                tripInfos={tripInfos}
                                                priceInfos={priceInfos}
                                                searchQuery={searchQuery}
                                                order={order}
                                          />
                                    )}
                                    {optionId === "ticket-preview" && (
                                          <HotelTicket
                                                bookingId={bookingId}
                                                order={order}
                                                priceInfos={priceInfos}
                                                tripInfos={tripInfos}
                                                searchQuery={searchQuery}
                                                totalAmount={totalAmount}
                                                agentOrder={agentOrder}
                                                profile={profile}
                                          />
                                    )}
                                    {optionId === "feedback" && (
                                          <FlightBookingRate
                                                agentOrder={agentOrder}
                                          />
                                    )}
                              </>
                        ) : (
                              <Spinner />
                        )}
                  </BookingLayout>
            </>
      );
};

export default HotelBookingShow;

import React from "react";
import { PassengerAccordion, PassengerDetail, PassengerTable, PriceAccordionHeader } from "../../flight_bookings/TicketInfo/style";
import { AccordionBody, AccordionHeader } from "react-bootstrap";
import { ContactInfoIcon, MailIcon } from "../../../components/icons";

const HotelConfirmPassenger = ({ priceInfos, order }) => {
      const firstGuest =
            priceInfos?.ris &&
            priceInfos?.ris[0]?.ti &&
            priceInfos?.ris[0]?.ti[0];
            
      return (
            <>
                  <PassengerAccordion className="border-0 p-0" border={false}>
                        <PriceAccordionHeader display="block" className="p-0">
                              <AccordionHeader>
                                    Passenger (
                                    <span className="text-muted">{`${firstGuest?.fN} ${firstGuest?.lN}`}</span>
                                    )
                              </AccordionHeader>
                        </PriceAccordionHeader>
                        <AccordionBody>
                              <div className="card-third pt-0">
                                    <div className="card-body">
                                          <p className="third-title"></p>
                                          <div className="border-top">
                                                <PassengerTable
                                                      length={
                                                            priceInfos?.ris
                                                                  ?.length
                                                      }
                                                >
                                                      <thead className="header-third">
                                                            <tr>
                                                                  <th>S.No.</th>
                                                                  <th>Name</th>
                                                                  <th width="45%">
                                                                        Add Ons
                                                                  </th>
                                                            </tr>
                                                      </thead>
                                                      <tbody>
                                                            {priceInfos?.ris?.map(
                                                                  (
                                                                        guest,
                                                                        guestIndex
                                                                  ) =>
                                                                        guest?.ti?.map(
                                                                              (
                                                                                    item,
                                                                                    index
                                                                              ) => (
                                                                                    <tr
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <td className="text-muted">
                                                                                                {guestIndex *
                                                                                                      guest
                                                                                                            ?.ti
                                                                                                            ?.length +
                                                                                                      index +
                                                                                                      1}

                                                                                                .
                                                                                          </td>
                                                                                          <td className="text-muted">
                                                                                                <div className="passenger-details">
                                                                                                      {`${item?.ti} ${item?.fN} ${item?.lN}`}{" "}
                                                                                                </div>
                                                                                                <PassengerDetail>
                                                                                                      {firstGuest?.fN ===
                                                                                                            item?.fN && (
                                                                                                            <>
                                                                                                                  <span>
                                                                                                                        <ContactInfoIcon />{" "}
                                                                                                                        {
                                                                                                                              order
                                                                                                                                    ?.deliveryInfo
                                                                                                                                    ?.contacts
                                                                                                                        }
                                                                                                                  </span>
                                                                                                                  <span>
                                                                                                                        <MailIcon />{" "}
                                                                                                                        {
                                                                                                                              order
                                                                                                                                    ?.deliveryInfo
                                                                                                                                    ?.emails
                                                                                                                        }
                                                                                                                  </span>
                                                                                                            </>
                                                                                                      )}
                                                                                                </PassengerDetail>
                                                                                          </td>
                                                                                          <td>
                                                                                                -
                                                                                          </td>
                                                                                    </tr>
                                                                              )
                                                                        )
                                                            )}
                                                      </tbody>
                                                </PassengerTable>
                                          </div>
                                    </div>
                              </div>
                        </AccordionBody>
                  </PassengerAccordion>
            </>
      );
};

export default HotelConfirmPassenger;

import styled from "styled-components";

export const TicketContainer = styled.div`
      padding-top: ${(props) => props.padding || "164px"};
`;

export const MailContainer = styled.div`
      padding: 10px 15px;
      border: 1px solid rgb(204, 194, 194);
      border-radius: 15px;
      box-shadow: ${(props) =>
            props.borderShadow ||
            "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px"};
`;

export const FlightIcon = styled.tr`
      svg {
            width: 24px;
            height: 24px;
      }

      strong {
            margin-left: 10px;
      }
`;

export const FlightInfo = styled.td`
      display: flex;
      gap: 10px;
`;

export const FlightInfoLogo = styled.div`
      background-color: white;
      border: 1px solid #e2e2e2;
      border-radius: 5px;
      padding: 2.5px;
      height: 34px;
      width: 34px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
            width: ${(props) => props.width || "35px"};
            height: ${(props) => props.height || "35px"};
            border-radius: 5px;
      }
`;

export const ThirdContent = styled.div`
      display: flex;
      align-items: center;
      gap: 10px;

      .canvas {
            height: 70px;

            img {
                  height: 100%;
            }
      }

      span {
            font-size: 14px;
            color: #666;
      }

      .third-content__baggage {
            font-size: 14px;

            div {
                  line-height: 1.3;
            }
      }

      .third-content__pax {
            font-size: 14px;
      }
`;

export const FlightTable = styled.table`
      th {
            padding: 10px;
            text-align: left;
            border: none;
            align-items: center;
      }

      td {
            padding: ${(props) => (props.length === 1 ? "10px" : "5px 10px")};
            padding-bottom: ${(props) => props.length !== 1 && "0"};
            text-align: left;
            border: none;
            vertical-align: middle;

            &:first-child {
                  padding-top: ${(props) => props.length !== 1 && "0"};
            }
      }

      tr {
            &:last-child {
                  td {
                        padding-bottom: 10px;
                  }
            }
      }

      .ticket__number {
            font-size: 15px;
      }
`;

export const CardDescription = styled.div`
      padding-left: 10px;
      line-height: 2;
      margin-top: 10px;

      .card-title {
            margin-bottom: 5px;
      }

      ul {
            padding: 0;
            margin-left: 12px;

            li {
                  font-size: 14px;
                  line-height: 1.5;
            }
      }
`;

import React, { useState } from "react";
import { Card, CardBody, Col, Row, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import CardHeader from "../../components/common/CardHeader";
import FlightTableFilter from "./FlightTableFilter";
import { TableBorderBody } from "../../components/common/CardBody/style";
import ProductImage from "../../components/common/ProductImage";
import { Link } from "react-router-dom";

const FlightBookingList = () => {
      const [loading, setLoading] = useState(false);
      const [bookings, setBookings] = useState([]);
      const [agents, setAgents] = useState([]);

      return (
            <>
                  <Helmet>
                        <title>Flight Booking List</title>
                  </Helmet>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Flight Booking List"></CardHeader>
                                    <CardBody>
                                          <FlightTableFilter
                                                setBookings={setBookings}
                                                agents={agents}
                                          />
                                          <TableBorderBody
                                                hover
                                                textAlign="left"
                                          >
                                                <thead>
                                                      <tr>
                                                            <th width="150px">
                                                                  -
                                                            </th>
                                                            <th>
                                                                  Booking Code
                                                            </th>
                                                            <th>
                                                                  Agent Detail
                                                            </th>
                                                            <th>Summary</th>
                                                            <th>Status</th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {!loading ? (
                                                            bookings.length >
                                                            0 ? (
                                                                  bookings?.map(
                                                                        (
                                                                              booking,
                                                                              index
                                                                        ) => (
                                                                              <>
                                                                                    <tr
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <td>
                                                                                                {
                                                                                                      booking?.date
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            <Link
                                                                                                                  to={`/dashboard/flight_bookings/${booking?.booking_id}/ticket-info`}
                                                                                                            >
                                                                                                                  {
                                                                                                                        booking?.booking_id
                                                                                                                  }
                                                                                                            </Link>
                                                                                                      }
                                                                                                      category={`PNR: ${booking?.pnr_number}`}
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                <ProductImage
                                                                                                      title={
                                                                                                            booking?.agent_name
                                                                                                      }
                                                                                                      category={`Company: ${booking?.company_name}`}
                                                                                                />
                                                                                          </td>
                                                                                          <td>
                                                                                                {
                                                                                                      booking?.summary
                                                                                                }
                                                                                          </td>
                                                                                          <td>
                                                                                                {
                                                                                                      booking?.status
                                                                                                }
                                                                                          </td>
                                                                                    </tr>
                                                                              </>
                                                                        )
                                                                  )
                                                            ) : (
                                                                  <>
                                                                        <tr>
                                                                              <td
                                                                                    colSpan={
                                                                                          5
                                                                                    }
                                                                                    className="text-center"
                                                                              >
                                                                                    No
                                                                                    booking
                                                                                    found
                                                                              </td>
                                                                        </tr>
                                                                  </>
                                                            )
                                                      ) : (
                                                            <>
                                                                  <tr>
                                                                        <td
                                                                              colSpan={
                                                                                    5
                                                                              }
                                                                              className="text-center"
                                                                        >
                                                                              <Spinner />
                                                                        </td>
                                                                  </tr>
                                                            </>
                                                      )}
                                                </tbody>
                                          </TableBorderBody>
                                    </CardBody>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default FlightBookingList;

import { Rating } from "@mui/material";
import React from "react";
import { PriceSummary } from "../FlightPayment/style";

const FlightBookingRate = ({ agentOrder }) => {
      return (
            <>
                  <PriceSummary>
                        <div className="price__box py-3 mb-4">
                              <div className="price__heading mb-0">
                                    <Rating
                                          name="size-large"
                                          value={agentOrder?.feedback?.rate}
                                          size="large"
                                    />
                              </div>
                              <p className="w-100 border-top pt-3 mt-3">
                                    {agentOrder?.feedback?.feedback}
                              </p>
                        </div>
                  </PriceSummary>
            </>
      );
};

export default FlightBookingRate;

import styled from "styled-components";
import { Accordion, Table } from "react-bootstrap";

export const FlightDetailContainer = styled.div`
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      background: #fff;
      border-radius: 10px;
      padding: ${(props) => props.padding || "30px 30px"};

      iframe {
            height: 60vh;
      }
`;

export const PassengerAccordion = styled(Accordion)`
      border-bottom: ${(props) =>
            props.border ? props.border : "1px solid #e5e3e3"};
      border-top: ${(props) =>
            props.border ? props.border : "1px solid #e5e3e3"};

      .total {
            padding: 0 0px;
      }

      .accordion-button {
            padding: 7px 0px;
            font-size: 18px;

            &:focus,
            &:not(.collapsed) {
                  box-shadow: none;
                  background-color: transparent;
            }
      }

      .accordion-body {
            padding: 0;
      }

      .price__item {
            display: flex;
      }
`;

export const PassengerTable = styled(Table)`
      font-size: 14px;

      tr {
            td {
                  &:nth-child(2) {
                        div:first-child {
                              font-weight: 600;
                        }
                  }
            }
      }
`;

export const PassengerDetail = styled.div`
      display: flex;
      font-size: 14px;
      align-items: center;
      gap: 10px;

      span {
            display: flex;
            align-items: center;
            gap: 2px;
            color: #666;

            span {
                  font-size: 20px;
            }
      }
`;

export const PriceAccordionHeader = styled.div`
      display: ${(props) => props.display || "flex"};
      align-items: center;
      justify-content: space-between;

      span {
            font-size: 14px;
      }
`;

export const FlightDetailInfo = styled.div`
      margin-top: ${(props) => props.marginTop || "1rem"};
      border-bottom: ${(props) => props.border || "1px dashed #224082"};
      padding-bottom: ${(props) => props.paddingBottom || "10px"};
      border-top: ${(props) => props.border || "1px dashed #224082"};
      padding-top: ${(props) => props.paddingTop || "10px"};
      font-size: ${(props) => props.fontSize};
      margin-bottom: ${(props) => props.marginBottom || "10px"};
      flex-direction: ${(props) => props.flexDirection};
`;

export const FlightDetailTitle = styled.div`
      color: #1a1a1a;
      font-size: 19px;
      font-weight: 400;
      line-height: 28.57px;
      text-align: left;
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: ${(props) => props.marginBottom};
      justify-content: ${(props) => props.justifyContent};
      flex-direction: ${(props) => props.flexDirection};

      span {
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #6a6868;
      }
`;

export const FlightDetailAirline = styled.div`
      display: flex;
      justify-content: space-between;
      align-items: start;
      margin-bottom: ${(props) => props.marginBottom};
      margin-top: ${(props) => props.marginTop};
      font-size: 14px;
      border-top: ${(props) =>
            props.border && "1px solid" + props.theme.primary};
      flex-direction: ${(props) => props.flexDirection};

      .flight__price-content {
            width: 33.33%;
      }

      .flight__trip-title {
            width: 100%;
            margin-top: 5px;
            color: ${(props) => props.theme.primary};
      }

      .flight__price-header {
            font-weight: 600;
      }
`;

export const FlightAirline = styled.div`
      display: flex;
      gap: 10px;
      flex-direction: ${(props) => props.flexDirection};
      align-items: ${(props) => (props.flexDirection ? "start" : "center")};
      width: ${(props) => props.width};
`;

export const FlightAirlineTitle = styled.div`
      div {
            font-size: ${(props) => props.fontSize || "20px"};
            font-weight: 500;
            line-height: ${(props) => props.lineHeight || "34.29px"};
      }
      span {
            color: #6a6868;
            font-size: 12px;
            font-weight: 400;
            line-height: 17.14px;
            display: block;
      }
`;

export const FlightAirlineTime = styled.div`
      width: ${(props) => props.width};

      div {
            font-size: ${(props) => props.fontSize || "20px"};
            font-weight: 500;
            line-height: ${(props) => props.lineHeight || "34.29px"};
      }

      span {
            color: #6a6868;
            font-size: 12px;
            font-weight: 400;
            line-height: 17.14px;
            display: block;
      }
`;

export const FlightRefund = styled.div`
      display: flex;
      flex-direction: column;
      gap: ${(props) => props.gap || "0.8rem"};

      .flight__duration {
            font-size: 14px;
            font-weight: 500;
            line-height: 17.14px;
            text-align: center;
            color: #6a6868;
      }

      .line {
            display: block;
            width: 100%;
            height: 1px;
            background: #b6afafee;
      }

      .flight__refund {
            color: #2dca1c;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            border: 2px solid #2dca1c;
            width: 130px;
            border-radius: 45px;
            /* border: 1px 0px 0px 0px; */
            padding: 4px 16px;
            min-width: max-content;
      }

      span {
            color: #6a6868;
            font-size: 12px;
            font-weight: 400;
            line-height: 17.14px;
            display: block;
      }
`;

export const FlightLayoverContainer = styled.div`
      width: 70%;
      margin: 1.125rem 15%;
      position: relative;
      display: flex;
      align-content: center;
      justify-content: center;

      p {
            margin-bottom: 0;
      }
`;

export const FlightLayoverLine = styled.div`
      left: 0;
      top: 50%;
      width: 100%;
      height: 0.0625rem;
      position: absolute;
      background: #eaeaea;
`;

export const FlightLayoverContent = styled.div`
      z-index: 9;
      position: relative;
      display: inline-block;
      background: #fff;
      padding: 0.25rem 2.5rem;
      border: 1px solid #eaeaea;
      text-align: center;
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 1.875rem 1.875rem 1.875rem 1.875rem;
`;

export const FlightLayoverTitle = styled.span`
      color: ${(props) => props.color};
      font-size: ${(props) => props.fontSize || "0.8125rem"};
      margin-top: ${(props) => props.marginTop};
      margin-bottom: 0;
      font-weight: ${(props) => props.fontWeight};
`;

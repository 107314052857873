import React from "react";
import {
      MainDashboard,
      ProfileCardBody,
      ProfileContainer,
      ProfileContent,
      ProfileListGroup,
      ProfileSidebar,
} from "../../profile/ProfileLayout/styles";
import MenuItem from "../../components/profile/MenuItem";
import { Helmet } from "react-helmet";

const options = [
      {
            title: "Ticket Info",
            slug: "ticket-info",
      },
      {
            title: "Ticket Preview",
            slug: "ticket-preview",
      },
      {
            title: "Payment Statement",
            slug: "payment-statement",
      },
      {
            title: "Raise Request",
            slug: "raise-request",
      },
      {
            title: "Feedback",
            slug: "feedback",
      },
      // {
      //       title: "Agent Info",
      //       slug: "agent-info",
      // },
];

const BookingLayout = ({ children, bookingId, optionId, title, slug }) => {
      const currentOption = options?.find((item) => item.slug === optionId);
      return (
            <>
                  <Helmet>
                        <title>{`${title} ${currentOption?.title}`}</title>
                  </Helmet>
                  <MainDashboard>
                        <ProfileContainer>
                              <ProfileSidebar>
                                    <ProfileCardBody>
                                          <ProfileListGroup>
                                                {options?.map((item, index) => (
                                                      <MenuItem
                                                            key={index}
                                                            title={item?.title}
                                                            link={`/dashboard/${slug}/${bookingId}/${item?.slug}`}
                                                      />
                                                ))}
                                          </ProfileListGroup>
                                    </ProfileCardBody>
                              </ProfileSidebar>
                        </ProfileContainer>
                        <ProfileContent backgroundColor="#f4f4f4">
                              {children}
                        </ProfileContent>
                  </MainDashboard>
            </>
      );
};

export default BookingLayout;

import React from "react";
import { FlightAirline, FlightAirlineTime, FlightAirlineTitle, FlightDetailAirline, FlightDetailContainer, FlightDetailInfo, FlightDetailTitle, FlightLayoverContainer, FlightLayoverContent, FlightLayoverLine, FlightLayoverTitle, FlightRefund } from "./style";
import { calculateDuration, calculateTimeDifference, convertDate, formatTime } from "../../../data/format";
import BookingRule from "./BookingRule";
import { FlightInfoLogo } from "../FlightTicketLayout/style";
import { BaggageIcon, TripIcon } from "../../../components/icons";

const FlightDetail = ({ tripInfos, bookingId }) => {
      return (
            <>
                  <FlightDetailContainer padding="16px 20px" className="mb-4">
                        {tripInfos?.map((info) => (
                              <FlightDetailInfo
                                    marginTop="0rem"
                                    marginBottom="0"
                                    paddingTop="0"
                                    border={true}
                              >
                                    {info?.sI?.map((item, index) => (
                                          <>
                                                {index === 0 && (
                                                      <FlightDetailTitle
                                                            marginBottom="5px"
                                                            justifyContent="space-between"
                                                      >
                                                            <div>
                                                                  {
                                                                        item?.da
                                                                              ?.city
                                                                  }{" "}
                                                                  -{" "}
                                                                  {
                                                                        info
                                                                              ?.sI[
                                                                              info
                                                                                    ?.sI
                                                                                    ?.length -
                                                                                    1
                                                                        ]?.aa
                                                                              ?.city
                                                                  }{" "}
                                                                  |{" "}
                                                                  <span>
                                                                        {convertDate(
                                                                              item?.dt
                                                                        )}
                                                                  </span>
                                                            </div>
                                                            <BookingRule
                                                                  bookingId={
                                                                        bookingId
                                                                  }
                                                                  leavingFrom={
                                                                        item?.da
                                                                              ?.code
                                                                  }
                                                                  destination={
                                                                        info
                                                                              ?.sI[
                                                                              info
                                                                                    ?.sI
                                                                                    ?.length -
                                                                                    1
                                                                        ]?.aa
                                                                              ?.code
                                                                  }
                                                                  refundStatus={
                                                                        item?.bI
                                                                              ?.tI[0]
                                                                              ?.fd
                                                                              ?.rT
                                                                  }
                                                            />
                                                      </FlightDetailTitle>
                                                )}
                                                <div>
                                                      <FlightDetailAirline>
                                                            <FlightAirline>
                                                                  <FlightInfoLogo>
                                                                        <img
                                                                              src={`/images/AirlinesLogo/${item?.fD?.aI?.code}.png`}
                                                                              alt={
                                                                                    item
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.name
                                                                              }
                                                                        />
                                                                  </FlightInfoLogo>
                                                                  <FlightAirlineTitle
                                                                        fontSize="16px"
                                                                        lineHeight="24px"
                                                                  >
                                                                        <div>
                                                                              {
                                                                                    item
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.name
                                                                              }
                                                                        </div>
                                                                        <span>
                                                                              {
                                                                                    item
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.code
                                                                              }{" "}
                                                                              {
                                                                                    item
                                                                                          ?.fD
                                                                                          ?.fN
                                                                              }
                                                                        </span>
                                                                  </FlightAirlineTitle>
                                                            </FlightAirline>
                                                            <FlightAirlineTime
                                                                  fontSize="16px"
                                                                  lineHeight="24px"
                                                            >
                                                                  <div>
                                                                        {formatTime(
                                                                              item?.dt
                                                                        )}
                                                                  </div>
                                                                  <span>
                                                                        {
                                                                              item
                                                                                    ?.da
                                                                                    ?.city
                                                                        }{" "}
                                                                        (
                                                                        {
                                                                              item
                                                                                    ?.da
                                                                                    ?.code
                                                                        }
                                                                        )
                                                                  </span>
                                                            </FlightAirlineTime>
                                                            <FlightRefund gap="0.2rem">
                                                                  <div className="flight__duration">
                                                                        {`${
                                                                              calculateDuration(
                                                                                    item?.duration
                                                                              )
                                                                                    .hours
                                                                        }h ${
                                                                              calculateDuration(
                                                                                    item?.duration
                                                                              )
                                                                                    .minutes
                                                                        }m`}
                                                                  </div>
                                                                  <div className="line"></div>
                                                                  <span>
                                                                        {item?.stops >
                                                                        0
                                                                              ? "1 Stops(+)"
                                                                              : "Non-Stop"}
                                                                  </span>
                                                            </FlightRefund>
                                                            <FlightAirlineTime
                                                                  fontSize="16px"
                                                                  lineHeight="24px"
                                                            >
                                                                  <div>
                                                                        {formatTime(
                                                                              item?.at
                                                                        )}
                                                                  </div>
                                                                  <span>
                                                                        {
                                                                              item
                                                                                    ?.aa
                                                                                    ?.city
                                                                        }{" "}
                                                                        (
                                                                        {
                                                                              item
                                                                                    ?.aa
                                                                                    ?.code
                                                                        }
                                                                        )
                                                                  </span>
                                                            </FlightAirlineTime>
                                                      </FlightDetailAirline>
                                                      <FlightDetailInfo
                                                            marginTop="0.5rem"
                                                            fontSize="13px"
                                                            paddingTop="6px"
                                                            paddingBottom="6px"
                                                            marginBottom="0"
                                                      >
                                                            <TripIcon
                                                                  width="17px"
                                                                  height="17px"
                                                            />{" "}
                                                            Check-In Baggage:{" "}
                                                            {
                                                                  item?.bI
                                                                        ?.tI[0]
                                                                        ?.fd?.bI
                                                                        ?.iB
                                                            }
                                                            <BaggageIcon
                                                                  width="17px"
                                                                  height="17px"
                                                                  classes="ms-3"
                                                            />{" "}
                                                            Cabin Baggage:{" "}
                                                            {
                                                                  item?.bI
                                                                        ?.tI[0]
                                                                        ?.fd?.bI
                                                                        ?.cB
                                                            }
                                                      </FlightDetailInfo>
                                                </div>
                                                {info?.sI?.length > 1 &&
                                                      info?.sI?.length !==
                                                            index + 1 && (
                                                            <FlightLayoverContainer className="my-4">
                                                                  <FlightLayoverLine />
                                                                  <FlightLayoverContent>
                                                                        <FlightLayoverTitle
                                                                              fontWeight="600"
                                                                              color="#224082"
                                                                        >
                                                                              Plane
                                                                              Change
                                                                        </FlightLayoverTitle>
                                                                        <FlightLayoverTitle
                                                                              fontSize="0.875rem"
                                                                              color="#555"
                                                                        >
                                                                              <span>
                                                                                    Layover
                                                                                    at
                                                                                    {` `}
                                                                                    {
                                                                                          item
                                                                                                ?.aa
                                                                                                ?.city
                                                                                    }

                                                                                    ,
                                                                                    {` ${
                                                                                          calculateTimeDifference(
                                                                                                item?.at,
                                                                                                tripInfos[0]
                                                                                                      ?.sI[
                                                                                                      index +
                                                                                                            1
                                                                                                ]
                                                                                                      ?.dt
                                                                                          )
                                                                                                .hours
                                                                                    }h ${
                                                                                          calculateTimeDifference(
                                                                                                item?.at,
                                                                                                tripInfos[0]
                                                                                                      ?.sI[
                                                                                                      index +
                                                                                                            1
                                                                                                ]
                                                                                                      ?.dt
                                                                                          )
                                                                                                .minutes
                                                                                    }m`}
                                                                              </span>
                                                                        </FlightLayoverTitle>
                                                                  </FlightLayoverContent>
                                                            </FlightLayoverContainer>
                                                      )}
                                          </>
                                    ))}
                              </FlightDetailInfo>
                        ))}
                  </FlightDetailContainer>
            </>
      );
};

export default FlightDetail;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { BookingFilter, FilterButton } from "../flight_bookings/style";
import {
      Button,
      Col,
      Form,
      FormControl,
      FormLabel,
      Row,
      Spinner,
} from "react-bootstrap";
import Select from "react-select";
import toast from "react-hot-toast";

const searchOptions = [
      {
            label: "PNR Number",
            value: "PNR Number",
      },
      {
            label: "Booking Code",
            value: "Booking Code",
      },
];

const statusOptions = [
      {
            label: "Confirmed",
            value: "Confirmed",
      },
      {
            label: "Cancelled",
            value: "Cancelled",
      },
      {
            label: "Hold",
            value: "Hold",
      },
      {
            label: "Pending",
            value: "Pending",
      },
];

const convertDate = (dateStr) => {
      const date = new Date(dateStr);

      const formattedDate = date.toISOString().split("T")[0];
      return formattedDate;
};

const HotelTableFilter = ({ setBookings, agents }) => {
      const [loadingData, setLoadingData] = useState(false);
      const [agent, setAgent] = useState("");
      const [searchOption, setSearchOption] = useState("");
      const [statusOption, setStatusOption] = useState("");
      const [startingDate, setStartingDate] = useState(new Date());
      const [endingDate, setEndingDate] = useState("");
      const [searchValue, setSearchValue] = useState("");
      const token = JSON.parse(localStorage.getItem("token"));

      const handleForm = async (event) => {
            event.preventDefault();
            setLoadingData(true);
            await axios
                  .get(
                        `${
                              process.env.REACT_APP_SECRET_KEY
                        }/api/dashboard/hotel_bookings?order_type=HOTEL&startingDate=${convertDate(
                              startingDate
                        )}&endingDate=${
                              endingDate ? convertDate(endingDate) : ""
                        }&searchOption=${
                              searchOption ? searchOption?.value : ""
                        }&statusOption=${
                              statusOption ? statusOption?.value : ""
                        }&searchValue=${searchValue}&user_id=${
                              agent ? agent?.value : ""
                        }`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBookings(response.data.bookings);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoadingData(false);
      };

      const handleClear = () => {
            setSearchOption("");
            setStatusOption("");
            setStartingDate(new Date());
            setEndingDate("");
            setSearchValue("");
            setAgent("");
      };

      const loadData = async () => {
            await axios
                  .get(
                        `${
                              process.env.REACT_APP_SECRET_KEY
                        }/api/dashboard/hotel_bookings?order_type=HOTEL&startingDate=${convertDate(
                              startingDate
                        )}&endingDate=${
                              endingDate ? convertDate(endingDate) : ""
                        }&searchOption=${
                              searchOption ? searchOption?.value : ""
                        }&statusOption=${
                              statusOption ? statusOption?.value : ""
                        }&searchValue=${searchValue}&user_id=${
                              agent ? agent?.value : ""
                        }`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setBookings(response.data.bookings);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <BookingFilter>
                        <Form onSubmit={handleForm}>
                              <Row className="mb-4 g-3 pb-4 border-bottom">
                                    <Col lg={8}>
                                          <FormLabel>Chose One</FormLabel>
                                          <Row className="g-0">
                                                <Col lg={4}>
                                                      <Select
                                                            options={agents}
                                                            value={agent}
                                                            onChange={setAgent}
                                                            isClearable
                                                            placeholder="Agent List"
                                                      />
                                                </Col>
                                          </Row>
                                    </Col>
                                    <Col lg={4}>
                                          <FormLabel>Chose One</FormLabel>
                                          <Row className="g-0">
                                                <Col lg={5}>
                                                      <Select
                                                            options={
                                                                  searchOptions
                                                            }
                                                            value={searchOption}
                                                            onChange={
                                                                  setSearchOption
                                                            }
                                                            placeholder="PNR Number"
                                                      />
                                                </Col>
                                                <Col lg={7}>
                                                      <FormControl
                                                            type="text"
                                                            onChange={(event) =>
                                                                  setSearchValue(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                            value={searchValue}
                                                      />
                                                </Col>
                                          </Row>
                                    </Col>
                                    <Col lg={3}>
                                          <FormLabel>Status</FormLabel>
                                          <Select
                                                options={statusOptions}
                                                placeholder="Booking Status"
                                                onChange={setStatusOption}
                                                value={statusOption}
                                          />
                                    </Col>
                                    <Col lg={3}>
                                          <FormLabel>
                                                Booking Date Start
                                          </FormLabel>
                                          <FormControl
                                                type="date"
                                                onChange={(event) =>
                                                      setStartingDate(
                                                            event.target.value
                                                      )
                                                }
                                                value={convertDate(
                                                      startingDate
                                                )}
                                          />
                                    </Col>
                                    <Col lg={3}>
                                          <FormLabel>
                                                Booking Date End
                                          </FormLabel>
                                          <FormControl
                                                type="date"
                                                onChange={(event) =>
                                                      setEndingDate(
                                                            event.target.value
                                                      )
                                                }
                                                value={
                                                      endingDate
                                                            ? convertDate(
                                                                    endingDate
                                                              )
                                                            : ""
                                                }
                                          />
                                    </Col>
                                    <Col lg={3}>
                                          <FilterButton type="submit">
                                                {loadingData ? (
                                                      <Spinner />
                                                ) : (
                                                      "Search"
                                                )}
                                          </FilterButton>
                                          <Button
                                                onClick={handleClear}
                                                type="button"
                                                className="btn btn-link btn-danger mx-3 text-decoration-none"
                                          >
                                                Clear All
                                          </Button>
                                    </Col>
                              </Row>
                        </Form>
                  </BookingFilter>
            </>
      );
};

export default HotelTableFilter;

import React from "react";
import { CommonButtonContainer } from "./style";
import { Link } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";

const CommonButton = ({
      title,
      slug,
      display,
      type,
      marginTop,
      marginBottom,
      button,
      loading,
      background,
      color,
      handleClick,
      padding,
      fontSize,
      width,
      icon,
      justifyContent,
      leftIcon,
      classes,
}) => {
      return (
            <>
                  <CommonButtonContainer
                        style={{ marginTop, marginBottom }}
                        padding={padding}
                        fontSize={fontSize}
                        width={width}
                        justifyContent={justifyContent}
                        className={classes}
                  >
                        {button ? (
                              <Button
                                    type={type ? type : "submit"}
                                    style={{ background, color }}
                                    onClick={
                                          handleClick ? handleClick : undefined
                                    }
                              >
                                    {leftIcon && leftIcon} {title}{" "}
                                    {icon && icon}
                                    {loading && <Spinner />}
                              </Button>
                        ) : (
                              <Link
                                    to={slug}
                                    type={type}
                                    className="custom-button"
                                    style={{ display }}
                              >
                                    {title}
                              </Link>
                        )}
                  </CommonButtonContainer>
            </>
      );
};

export default CommonButton;

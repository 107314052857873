import styled from "styled-components";

export const FlightConfirmationDownload = styled.div`
      background-color: ${(props) => props.theme.white};
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      margin-bottom: 1.5rem;
      border-radius: 12px 10px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
`;

export const FlightConfirmationHeader = styled.div`
      display: flex;
      gap: 10px;

      .confirmation__header-img {
            height: 60px;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
            }
      }

      .confirmation__header-title {
            font-size: 24px;
            font-weight: 500;
      }

      .confirmation__header-subtitle {
            font-size: 16px;
            color: #666;

            span {
                  color: ${(props) => props.theme.primaryColor};
                  font-weight: 600;
            }
      }
`;

export const FlightPriceItem = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      input {
            width: 120px;
      }

      .grand__total {
            font-weight: 600;
      }
`;

export const ButtonContainer = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 2rem;
`;

export const ButtonContent = styled.div`
      display: flex;
      align-items: center;
      width: 100%;
      padding: 8px 20px;
      justify-content: space-between;
      gap: 20px;
      background-color: ${(props) => props.theme.white};
      border-radius: 5px;
      border: 1px solid #ccc;

      .btn-print {
            background-color: ${(props) => props.theme.primaryColor};
            border: 1px solid #f4f4f4;
            color: #f4f4f4;
            transition: all 0.3s ease-in-out;

            &:first-child {
                  margin-right: 10px;
            }

            &:hover {
                  background-color: #142a5a;
            }
      }
`;

export const ExtraContent = styled.div`
      display: flex;
      align-items: center;
`;

import { format } from "date-fns";

const getCurrentDate = () => {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
};
export default getCurrentDate;

export const convertDate = (isoString) => {
      const date = new Date(isoString);
      return format(date, "EEE - dd MMM yyyy");
};

export const convertFullDate = (isoString) => {
      const date = new Date(isoString);
      return format(date, "dd MMM yyyy");
};

export const formatTime = (isoString) => {
      const date = new Date(isoString);
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${hours}:${minutes}`;
};

export const convertAmount = (amount) => {
      return parseFloat(amount).toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
      });
};

export const convertFloat = (amount) => {
      return parseFloat(amount).toFixed(2);
};

export const calculateDuration = (totalMinutes) => {
      if (typeof totalMinutes !== "number" || isNaN(totalMinutes)) {
            return { hours: "00", minutes: "00" };
      }

      const hours = String(Math.floor(totalMinutes / 60)).padStart(2, "0"); // Ensures two digits for hours
      const minutes = String(totalMinutes % 60).padStart(2, "0"); // Ensures two digits for minutes

      return { hours, minutes };
};

export const calculateTimeDifference = (isoString1, isoString2) => {
      const date1 = new Date(isoString1);
      const date2 = new Date(isoString2);

      const differenceInMs = Math.abs(date1 - date2);

      const totalMinutes = Math.floor(differenceInMs / 60000);

      return calculateDuration(totalMinutes);
};

export const handleHotelCommissionTotal = ({ prices, commissionsAmount }) => {
      let totalPrice = 0;

      prices?.map((item) => {
            if (commissionsAmount) {
                  totalPrice += item?.tp + parseFloat(commissionsAmount);
            } else {
                  totalPrice += item?.tp;
            }
      });

      return totalPrice;
};

export function convertDayMonth(dateInput) {
      const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
      ];

      // Create a Date object
      const date = new Date(dateInput);

      // Extract the day, date, month, and year
      const dayOfMonth = date.getDate();
      const month = months[date.getMonth()];

      // Format the date
      return `${dayOfMonth < 10 ? "0" : ""}${dayOfMonth} ${month}`;
}

export function convertDayYear(dateInput) {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      // Create a Date object
      const date = new Date(dateInput);

      // Extract the day, date, month, and year
      const day = days[date.getDay()];
      const year = date.getFullYear();

      // Format the date
      return `${day}, ${year}`;
}

export function convertFormatDay(dateInput) {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
      ];

      // Create a Date object
      const date = new Date(dateInput);

      // Extract the day, date, month, and year
      const day = days[date.getDay()];
      const dayOfMonth = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      // Format the date
      return `${day}, ${
            dayOfMonth < 10 ? "0" : ""
      }${dayOfMonth} ${month} ${year}`;
}

export const calculateTotalNights = (checkinDate, checkoutDate) => {
      const checkin = new Date(checkinDate);
      const checkout = new Date(checkoutDate);

      if (isNaN(checkin) || isNaN(checkout)) {
            return 0;
      }

      const timeDifference = checkout - checkin;

      const totalNights = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      return totalNights > 0 ? totalNights : 0;
};

export const calculateTotalAdults = (roomInfo) => {
      return roomInfo?.reduce((sum, room) => sum + room.numberOfAdults, 0);
};

export const calculateTotalChild = (roomInfo) => {
      return roomInfo?.reduce((sum, room) => sum + room.numberOfChild, 0);
};

export function formatDateTime(dateInput) {
      // Create a Date object
      const date = new Date(dateInput);

      // Extract components
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero
      const day = date.getDate().toString().padStart(2, "0"); // Add leading zero
      const hours = date.getHours().toString().padStart(2, "0"); // Add leading zero
      const minutes = date.getMinutes().toString().padStart(2, "0"); // Add leading zero
      const seconds = date.getSeconds().toString().padStart(2, "0"); // Add leading zero

      // Format the string
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BookingLayout from "./BookingLayout";
import FlightTicket from "./FlightTicket";
import axios from "axios";
import toast from "react-hot-toast";
import { Spinner } from "react-bootstrap";
import TicketInfo from "./TicketInfo";
import FlightPayment from "./FlightPayment";
import FlightBookingRate from "./FlightBookingRate";

const FlightBookingShow = () => {
      const [loading, setLoading] = useState(false);
      const { optionId } = useParams();
      const { bookingId } = useParams();
      const [agentOrder, setAgentOrder] = useState([]);
      const [profile, setProfile] = useState([]);

      const [order, setOrder] = useState([]);
      const [tripInfos, setTripInfos] = useState([]);
      const [totalPriceInfo, setTotalPriceInfo] = useState([]);
      const [travellerInfos, setTravellerInfos] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const loadingData = async () => {
            setLoading(true);
            const response = await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/flight_bookings/${bookingId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .catch((error) => {
                        toast.error(error.message);
                  });

            if (response?.data?.result === "success") {
                  setAgentOrder(response.data.agent);
                  setProfile(response.data.agentDetail);

                  await axios
                        .post(
                              `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/booking-details`,
                              {
                                    bookingId:
                                          response?.data?.agent?.tj_booking_id,
                                    requirePaxPricing: true,
                              },
                              {
                                    headers: {
                                          apikey: process.env
                                                .REACT_APP_Flight_Api_Key,
                                    },
                              }
                        )
                        .then((response) => {
                              if (response.data.status?.success === true) {
                                    setOrder(response.data.order);
                                    setTripInfos(
                                          response.data.itemInfos?.AIR
                                                ?.tripInfos
                                    );
                                    setTotalPriceInfo(
                                          response.data.itemInfos?.AIR
                                                ?.totalPriceInfo
                                                ?.totalFareDetail
                                    );
                                    setTravellerInfos(
                                          response.data.itemInfos?.AIR
                                                ?.travellerInfos
                                    );
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            }
            setLoading(false);
      };

      useEffect(() => {
            loadingData();
      }, []);

      return (
            <>
                  <BookingLayout
                        title="Flight"
                        optionId={optionId}
                        slug="flight_bookings"
                        bookingId={bookingId}
                  >
                        {!loading ? (
                              <>
                                    {optionId === "ticket-preview" && (
                                          <FlightTicket
                                                agentOrder={agentOrder}
                                                bookingId={bookingId}
                                                order={order}
                                                profile={profile}
                                                travellerInfos={travellerInfos}
                                                tripInfos={tripInfos}
                                                totalPriceInfo={totalPriceInfo}
                                          />
                                    )}
                                    {optionId === "ticket-info" && (
                                          <TicketInfo
                                                agentOrder={agentOrder}
                                                travellerInfos={travellerInfos}
                                                order={order}
                                                tripInfos={tripInfos}
                                                bookingId={
                                                      agentOrder?.tj_booking_id
                                                }
                                          />
                                    )}
                                    {optionId === "payment-statement" && (
                                          <FlightPayment
                                                totalPriceInfo={totalPriceInfo}
                                                agentOrder={agentOrder}
                                                loading={loading}
                                                travellerInfos={travellerInfos}
                                          />
                                    )}
                                    {optionId === "feedback" && (
                                          <FlightBookingRate
                                                agentOrder={agentOrder}
                                          />
                                    )}
                              </>
                        ) : (
                              <Spinner />
                        )}
                  </BookingLayout>
            </>
      );
};

export default FlightBookingShow;
